/* global angular */

'use strict';

angular.module('managerApp').config( ($routeProvider) => {
    $routeProvider.when('/discounts', {
        templateUrl: 'app/discounts/list.html',
        controller: 'DiscountsController',
        hasPermissions: ['DISCOUNTS:GET']
    });
});
